<template>
  <div class="container">
    <div class="header flex-between-start">
      <div class="handle">
        <div class="title flex-align">
          活动列表
          <el-popover placement="bottom-start" title="活动说明" :width="600" trigger="click">
            <template #reference>
              <QuestionFilled style="width: 20px; height: 20px; margin-left: 5px; cursor: pointer"></QuestionFilled>
            </template>
            <div class="pop-content">{{ popContent }}</div>
          </el-popover>
        </div>
        <el-button type="primary" @click="handleEdit()" v-permission="['新增券活动']">新增</el-button>
      </div>
      <div class="search">
        <el-select v-model="search.status" placeholder="请选择优惠券状态" clearable>
          <el-option
            v-for="item in enums.couponStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          v-model="search.activity_key"
          type="text"
          placeholder="请输入活动号 | 活动名 | 主办方名称"
          clearable
        ></el-input>
        <el-select v-model="search.appid" placeholder="请选择接入方" clearable>
          <el-option v-for="item in appList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <div class="wrap mt-10">
          <el-select v-model="search.type" placeholder="请选择活动类型" clearable>
            <el-option
              v-for="item in enums.couponType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select v-model="search.category_id" placeholder="请选择活动分类" clearable>
            <el-option v-for="item in cateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-date-picker
            v-model="search.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="YYYY-MM-DD"
            size="default"
          />
          <el-button @click="pageEmit(1)">搜索</el-button>
        </div>
      </div>
    </div>

    <!-- 列表 -->
    <el-table :data="tableData.items" v-loading="loading" height="650">
      <el-table-column prop="id" label="活动id" width="75"></el-table-column>
      <el-table-column label="活动号" width="160">
        <template #default="{ row }">
          {{ row.up_coupon_id || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="type_cn" label="优惠券类型" width="130"></el-table-column>
      <el-table-column label="归属分类" width="90">
        <template #default="{ row }">
          {{ row.act_category?.name }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="优惠券名称" width="150"></el-table-column>
      <el-table-column prop="minus" label="优惠金额" width="90"></el-table-column>
      <el-table-column prop="" label="优惠券规则" width="110">
        <template #default="{ row }">
          <div>
            <div>满 {{ row.full_money }}</div>
            <div>减 {{ row.minus }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="activity_business_count" label="适用商家数" width="105">
        <template #default="{ row }">
          {{ row.business_type == 1 ? '商家通用' : row.activity_business_count }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template #default="{ row }">
          <el-tag size="small" :type="enums.couponStatus[row.status]?.status">{{
            enums.couponStatus[row.status]?.label
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="优惠券有效期" width="180">
        <template #default="{ row }">
          <div>
            <p>{{ row.start_time }}至</p>
            <p>{{ row.end_time }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="grant_nums" label="数量"></el-table-column>
      <el-table-column label="已领">
        <template #default="{ row }">
          {{ row.grant_nums - row.remaining_nums }}
        </template>
      </el-table-column>
      <el-table-column prop="remaining_nums" label="剩余"></el-table-column>
      <el-table-column prop="link_url" label="外链地址" width="200"></el-table-column>
      <el-table-column prop="activity_business_count" label="关联白名单规则">
        <template #default="{ row }">
          {{ row.white_rule && row.white_rule.id ? row.white_rule.name : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="220">
        <template #default="{ row }">
          <div class="flex-column">
            <div>
              <el-button
                plain
                size="small"
                type="primary"
                @click="handleEdit(row, 'copy')"
                v-permission="['新增券活动']"
                >复制</el-button
              >
              <el-button
                plain
                size="small"
                type="primary"
                @click="handleEdit(row)"
                v-if="row.status == 0 || row.status == 2"
                v-permission="['编辑券活动']"
                >编辑</el-button
              >
              <el-button plain size="small" @click="handleEdit(row, 'detail')" v-permission="['活动详情']"
                >查看</el-button
              >
            </div>
            <div class="mt-10">
              <el-button
                plain
                size="small"
                type="danger"
                @click="handleDelete(row.id)"
                v-if="row.status == 0"
                v-permission="['删除券活动']"
                >删除</el-button
              >
              <el-button
                plain
                size="small"
                type="warning"
                @click="handleStatus(row)"
                v-if="row.status !== 3"
                v-permission="['券活动开关']"
                >{{ ['开始', '暂停', '开始'][row.status] }}</el-button
              >
              <el-button
                plain
                size="small"
                type="warning"
                @click="handleOff(row)"
                v-if="row.status !== 0 && row.status !== 3"
                v-permission="['结束券活动']"
                >结束</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <page
      @page-event="pageEmit"
      :current-page="pageParam.currentPage"
      :total="tableData.total"
      :page-size="tableData.count"
    ></page>
  </div>
</template>

<script>
import { ref, onMounted, reactive } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus/lib/components'
import { useRouter, useRoute } from 'vue-router'
import activityModel from '@/model/activity'
import appModel from '@/model/application'
import commonHandle from '@/hooks/useCommon'
import enums from '@/lin/util/enum'

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const search = reactive({
      status: '',
      appid: '',
      activity_key: '',
      time: [],
      type: '',
      category_id: '',
    })
    const appList = ref([])
    const popContent = ref('')
    const cateList = ref([])

    // 获取优惠券列表
    const getData = async pageQuery => {
      loading.value = true
      let searchform = {
        ...search,
        start: search.time ? search.time[0] : '',
        end: search.time ? search.time[1] : '',
      }
      delete searchform.time
      try {
        const res = await activityModel.getActivityList({
          page: pageQuery?.page || pageParam.currentPage - 1,
          count: pageQuery?.count || pageParam.count,
          ...searchform,
        })
        tableData.value = res
        return res
      } finally {
        loading.value = false
      }
    }

    // 获取注册平台列表
    const getAppList = async () => {
      const res = await appModel.getApplicationListAll()
      appList.value = res
    }

    // 获取活动分类列表
    const getCateList = async () => {
      const res = await activityModel.getActCateListAll()
      cateList.value = res
    }

    // 编辑/新增
    const handleEdit = (item, type = '') => {
      router.push({
        path: '/activity/update',
        query: {
          id: item?.id,
          type,
        },
      })
    }

    // 改变券活动状态
    const handleStatus = async item => {
      let res
      loading.value = true
      try {
        res = await activityModel.changeActivityStatus(item.id)
        ElMessage.success(res.message)
      } finally {
        loading.value = false
        getData()
      }
    }

    // 结束券活动
    const handleOff = async item => {
      ElMessageBox.confirm('此操作将结束该优惠券活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        let res
        loading.value = true
        try {
          res = await activityModel.activityFinish(item.id)
          ElMessage.success(res.message)
        } finally {
          loading.value = false
          getData()
        }
      })
    }

    // 删除
    const deleteFun = async id => {
      let res
      res = await activityModel.deleteActivity(id)
      return res
    }

    const { tableData, loading, pageParam, handleDelete, pageEmit } = commonHandle(getData, deleteFun)

    onMounted(() => {
      search.activity_key = route.query?.id
      // eslint-disable-next-line max-len
      popContent.value = '1.活动开始：点击开始，将活动上架用户端预售展示，活动时间开始才可领券/购券；'
        + '\n注意：开始活动时，活动结束时间不能小于当前时间'
        + '\n\n2.活动暂停：点击暂停，将活动暂时下架，不在用户端展示，活动发生紧急情况可使用暂停，暂停的活动可重新开始；'
        + '\n\n3.活动时间：活动时间为优惠券领券的开始和结束时间；结束时间到活动自动下架并结束，结束的活动不可重新开始；'
        + '\n\n4.优惠券时效：领券成果后按优惠券时效计算有效期，如当有效期＞活动到期时间，按活动到期时间计算，即活动到期，优惠券自动失效；'
      getData()
      getAppList()
      getCateList()
    })

    return {
      pageParam,
      tableData,
      loading,
      appList,
      handleEdit,
      handleStatus,
      handleOff,
      handleDelete,
      getData,
      pageEmit,
      enums,
      search,
      cateList,
      popContent,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';

.header .search .el-input {
  width: 250px !important;
}
.pop-content {
  white-space: pre-wrap;
  line-height: 1.5rem;
}
</style>
